import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import ClockBar from '../components/ClockBar';
import TaskList from '../pages/TaskPage';
import Calendar from '../pages/CalendarPage';
import TaskCreator from 'src/pages/TaskCreatorPage';
import Heatmap from '../pages/HeatMapPage';
import '../styles/MainLayout.css';

import DemoPage from 'src/pages/DemoPage';
import LaytoutOne  from '../pages/DemoLayoutPage';
import LayoutTwo from '../pages/DemoTwoLayout';

const MainLayout: React.FC<{page:string, children: React.ReactNode }> = ({ page,children }) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  return (
    <div className={mode === 'dark' ? 'dark-mode' : 'light-mode'}>
      <ClockBar />
      <Navbar mode={mode} setMode={setMode} /> {/* Pass mode and setMode to Navbar */}
      
      <div className="main-layout">
      {/* Conditional render for differente pages*/}
        {page === "home" && (
          <>
          <div className="left-column"> <TaskCreator/> <TaskList/></div>
          <div className="right-column"><Calendar/><Heatmap /></div>
          {/* <div className="right-column"><Calendar /></div> */}
          </>
          )}
        
        {page === "task" && (
          <div className="single-column"><TaskCreator/><TaskList/></div>
          )}
        {page === "calendar" && (
          <div className="single-column"><Calendar /></div>
          )}
        {page === "taskCreator" && (
          <div className="single-column"><TaskCreator/></div>
          )}
        {page === "heatmap" && (
          <div className="single-column"><Heatmap /></div>
          )}
        {page === "demo" && (
          <div className="single-column"><DemoPage/></div>
          )}
        {page === "demolayoutOne" && (
          <div className="single-column"><LaytoutOne/></div>
          )}
        {page === "demoLayoutTwo" && (
          <div className="single-column"><LayoutTwo/></div>
          )}

      
      </div>


      {/* <div className="content">
        {children}
      </div> */}
    </div>
  );
};

export default MainLayout;
