import React from "react";
import TaskCreator from "../components/TaskCreator";

const TaskCreatorPage = () => {
  return (
    <div>
      <TaskCreator></TaskCreator>
    </div>
  );
}
export default TaskCreatorPage;