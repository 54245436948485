import React from "react";
import TaskTimerBar from "../components/DEMO_TaskTimerBar";
import ClockBar from "../components/ClockBar";
import Navbar from "../components/Navbar";
import { useFetch } from "../hooks/useFetch";

interface MessageResponse {
  data: string;
}
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const LayoutTwo = () => {
  //to debug demo clockbar
  const startTime = new Date(); // Start time: current time
  const endTime = new Date(startTime.getTime() + 1 * 60 * 1000); // End time: 1 hour from startTime
  const {
    data: messageResponse,
    error,
    loading,
  } = useFetch<MessageResponse>(`${apiBaseUrl}/message`);

  return (
    <div>
      <h1>Welcome to the Second Layout Demo Page</h1>
      <p>This is the Second Layout Demo page of our application.</p>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: 1, marginRight: "20px" }}>
          <h4>Task Timer Bars</h4>
          <p>Demo Timer with Conditions</p>

          <TaskTimerBar
            description="This Task is incoming"
            startTime={new Date(startTime.getTime() + 5 * 60 * 1000)}
            endTime={new Date(startTime.getTime() + 6 * 60 * 1000)}
            onComplete={() => {}}
            onStar={() => {}}
            onRemove={() => {}}
          />

          <TaskTimerBar
            description="This is a Task in Progress"
            startTime={startTime}
            endTime={endTime}
            onComplete={() => {}}
            onStar={() => {}}
            onRemove={() => {}}
          />

          <TaskTimerBar
            description="This is Task that is Complete"
            startTime={new Date(startTime.getTime() - 30 * 60 * 1000)}
            endTime={new Date(startTime.getTime() - 30 * 60 * 1000)}
            onComplete={() => {}}
            onStar={() => {}}
            onRemove={() => {}}
          />
        </div>
        <div
          style={{
            border: "2px, solid black",
            borderRadius: "8px",
            padding: "10px",
            flex: 1,
            position: "relative",
          }}
        >
          <h4>add heat map here</h4>
        </div>
      </div>
    </div>
  );
};

export default LayoutTwo;
