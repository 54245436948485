import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import { FaHome, FaTasks, FaCalendarAlt, FaProjectDiagram, FaSun, FaMoon } from 'react-icons/fa'; // FontAwesome icons for better UI

interface NavbarProps {
  mode: "light" | "dark";
  setMode: (mode: "light" | "dark") => void;
}

const Navbar: React.FC<NavbarProps> = ({ mode, setMode }) => {
  const toggleMode = () => {
    setMode(mode === "light" ? "dark" : "light"); // Toggle between light and dark mode
  };

  return (
    <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
      <ButtonGroup
        variant="contained"
        aria-label="navigation button group"
        sx={{
          '& .MuiButton-root': {
            backgroundColor: '#f87171',
            '&:hover': {
              backgroundColor: '#d15f5f', // Darker red on hover
            },
          },
        }}
      >
        <Button component={Link} to="/" startIcon={<FaHome />}>
          Home
        </Button>
        <Button component={Link} to="/task" startIcon={<FaTasks />}>
          Tasks
        </Button>
        <Button component={Link} to="/taskCreator" startIcon={<FaProjectDiagram />}>
          TaskCreator
        </Button>
        <Button component={Link} to="/calendar" startIcon={<FaCalendarAlt />}>
          Calendar
        </Button>
        <Button component={Link} to="/heatmap" startIcon={<FaProjectDiagram />}>
          Heatmap
        </Button>
        <Button component={Link} to="/demo" startIcon={<FaProjectDiagram />}>
          Demo
        </Button>
        <Button component={Link} to="/layout" startIcon={<FaProjectDiagram />}>
          Layout1
        </Button>
        <Button component={Link} to="/layoutTwo" startIcon={<FaProjectDiagram />}>
          Layout2
        </Button>
      </ButtonGroup>
      
      <IconButton onClick={toggleMode} style={{ fontSize: '34px' }}>
        {mode === "light" ? <FaMoon /> : <FaSun />}
      </IconButton>
    </nav>
  );
};

export default Navbar;
