import React, { useState } from 'react';
import axios from 'axios';  
// import "react-datepicker/dist/react-datepicker.css";
import { hardcodedServerValues as hsv } from 'src/utils/hardcodeServerValues';
import '../styles/TaskCreator.css';
import '../styles/Styleguide.css';
import { format } from 'path';
import DatePicker from "react-datepicker";
import '../styles/DatePicker.css';

// define interface for task creator
// interface TaskCreatorFormData{
//     taskTitle: string; 
//     taskStatus:boolean; // completed or not
//     timeStart: string; // time when the task is to start
//     timeEnd: string; // time when the task is due
//     taskUserID: string;
// }

interface Task {
    taskTitle: string;
    taskStatus: boolean;
    // creationDate: string;
    timeStart: string;
    timeEnd: string;
    // dueDate: string;
    taskUserID: string;
}

const TaskCreator: React.FC = () => {

    // define initial values for default task creator form data
    const currentTime = new Date()
    const defaultTimeStart = currentTime.toISOString(); // time the task was created is default if no value is provided
    const defaultTimeEnd = new Date(currentTime.getTime() + 60 * 60 * 1000).toISOString(); // 1 hour from the time we create the task

    // Task creator form data state
    const [formData, setFormData] = useState<Task>({
        // timeStart: new Date().toISOString(),
        // timeEnd: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(),
        taskTitle: '',
        taskStatus: false, // Default Status (flase = not completed)
        timeStart: defaultTimeStart, // if optiona this should have a default value of the time the task was created new Date().toISOString()
        timeEnd: defaultTimeEnd, //  if optiona this should have a default valuoe of 1 hour from the time the task was created new Date().toISOString()
        taskUserID: hsv.taskUserID // Hardcoded for now, you can dynamically set it
    });

    // handle form input change
    const [isSubmitting, setIsSubmitting] = useState(false); // set the isSubmitting state to false
    const [startDate, setStartDate] = useState<Date | null>(null); // set the startDate state to null
    const [showForm, setShowForm] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // helper to formate date-time 
    const formatDateTime=(date: Date) => {
        return date.toISOString().slice(0, 16);// ISO string and slice to "YYYY-MM-DDTHH:MM"
    };
        // handle input changes
    const handleInputChange =(e: React.ChangeEvent<HTMLInputElement>) => {
        const {name,value,type,checked} = e.target; // destructure the event target
        setFormData((prevFormData)=>({
            ...prevFormData,// ...spread the previous form data
            [name]: type === 'checkbox' ? checked : value // set the name to the value, name is dynamic[dynamic property]
        }));
    };
    const handleDateChange = (name: keyof Task, date: Date | null) => {
        if (date === null) return;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: date.toISOString()
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // prevent the default form submission
        setIsSubmitting(true); // set isSubmitting to true

        try{
            // make POST request to server
            const response = await axios.post(hsv.ApiTaskAddres, formData); // hooked to server
            console.log(response.data); // log the response data

            if(response.status === 201){// if the response status is 201 created
                alert('Task created successfully!'); // alert the user, find less annoying way of doing this
                // reset form data after successful submission
                setFormData({
                    taskTitle: '',
                    taskStatus: false,
                    timeStart: '',
                    timeEnd: '',
                    taskUserID: hsv.taskUserID
                });
            } else {
                alert('Failed to create task!');
            }
        } catch (error) {
            console.error("Error creating task:", error); // log the error
            alert("Error creating task. Please try again.");  // alert the user, also find less annoying way of doing this
        } finally {
            setIsSubmitting(false); // set isSubmitting to false
        }
    };

    const toggleForm = () => {
        if (showForm) {
            setIsVisible(false);
            setTimeout(() => setShowForm(false), 500); // Match the transition duration
        } else {
            setShowForm(true);
            setTimeout(() => setIsVisible(true), 0);
        }
    };

    return (
        <div className="task-creator">
            <h2 onClick={toggleForm}>Create a Task</h2>
            <div className={`form-container ${isVisible ? 'fade-in' : ''}`}>
                {showForm && (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="taskTitle">Task Title:</label>
                            <input
                                type="text"
                                id="taskTitle"
                                name="taskTitle"
                                placeholder='Enter task title'
                                value={formData.taskTitle}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="timeStart">Start Time:</label>
                            <input
                                type="datetime-local"
                                id="timeStart"
                                name="timeStart"
                                value={formData.timeStart}
                                onChange={handleInputChange}
                                // placeholder={formatDateTime(currentTime)}
                            />
                        </div>
                        <div>
                            <label htmlFor="timeEnd">End Time:</label>
                            <input
                                type="datetime-local"
                                id="timeEnd"
                                name="timeEnd"
                                value={formData.timeEnd}
                                onChange={handleInputChange}
                                // placeholder={formatDateTime(new Date(defaultTimeEnd))}
                            />
                        </div>

                        {/* Potential Date picker change if formating gets fixed lol*/}
                        {/* <div className='custom-datepicker'>
                            <label htmlFor="timeStart">Start Time:</label>
                            <DatePicker
                                selected={new Date(formData.timeStart)}
                                onChange={(date) => handleDateChange('timeStart', date)}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                                placeholderText="Select start date and time"
                            />
                        </div>
                        <div className='custom-datepicker'>
                            <label htmlFor="timeEnd">End Time:</label>
                            <DatePicker
                                selected={new Date(formData.timeEnd)}
                                onChange={(date) => handleDateChange('timeEnd', date)}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                                placeholderText="Select end date and time"
                            />
                        </div> */}
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default TaskCreator;