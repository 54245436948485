import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton"; // Import IconButton from Material UI
import { FaSun, FaMoon } from "react-icons/fa"; // Import icons for sun and moon

interface DarkModeProps {
  mode: "light" | "dark";
  setMode: (mode: "light" | "dark") => void;
}

const DarkMode: React.FC<DarkModeProps> = ({ mode, setMode }) => {
  useEffect(() => {
    document.documentElement.className = mode; // Set the class on <html>
  }, [mode]);

  const toggleMode = () => {
    setMode(mode === "light" ? "dark" : "light"); // Toggle between light and dark
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      <IconButton onClick={toggleMode} style={{ fontSize: "24px" }}>
        {mode === "light" ? <FaMoon /> : <FaSun />}
      </IconButton>
    </div>
  );
};

export default DarkMode;
