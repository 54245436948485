import React, { useState } from "react";
import ClockBar from "../components/ClockBar";
import TaskTimerBar from "../components/DEMO_TaskTimerBar";
import DarkMode from "../components/DarkMode";
import Navbar from "../components/Navbar";
import "../App.css";

const DemoLayoutPage = () => {
  const [mode, setMode] = useState<"light" | "dark">("light");
  const startTime = new Date();
  const endTime = new Date(startTime.getTime() + 1 * 60 * 1000);
  const textColor = mode === "dark" ? "#ffffff" : "#000000";

  return (
    <div
      style={{
        backgroundColor: mode === "dark" ? "#282c34" : "#f5f5f5",
        color: textColor,
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <DarkMode mode={mode} setMode={setMode} />
      <h1>Welcome to the Layout Demo Page</h1>
      <p>This is the layout demo page of our application.</p>
      <ClockBar />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div style={{ flex: 1, marginRight: "20px" }}>
          <h4>Task Timer Bars</h4>
          <p>Demo Timer with Conditions</p>

          <TaskTimerBar
            description="This Task is incoming"
            startTime={new Date(startTime.getTime() + 5 * 60 * 1000)}
            endTime={new Date(startTime.getTime() + 6 * 60 * 1000)}
            onComplete={() => {}}
            onStar={() => {}}
            onRemove={() => {}}
          />

          <TaskTimerBar
            description="This is a Task in Progress"
            startTime={startTime}
            endTime={endTime}
            onComplete={() => {}}
            onStar={() => {}}
            onRemove={() => {}}
          />

          <TaskTimerBar
            description="This is Task that is Complete"
            startTime={new Date(startTime.getTime() - 30 * 60 * 1000)}
            endTime={new Date(startTime.getTime() - 30 * 60 * 1000)}
            onComplete={() => {}}
            onStar={() => {}}
            onRemove={() => {}}
          />
        </div>

        <div
          style={{
            border: `2px solid ${mode === "dark" ? "#61dafb" : "#1976d2"}`,
            borderRadius: "8px",
            padding: "10px",
            flex: 1,
            position: "relative",
          }}
        >
          <h4
            style={{
              margin: 0,
              position: "absolute",
              top: "-10px",
              left: "10px",
              backgroundColor: mode === "dark" ? "#282c34" : "#f5f5f5",
              padding: "0 5px",
            }}
          >
            Heatmap Location
          </h4>
          <p>add heatmap here</p>
        </div>
      </div>
    </div>
  );
};

export default DemoLayoutPage;
