import React from 'react';
import Heatmap from 'src/components/Heatmap';
import useGetAllTasks from 'src/hooks/useGetAllTasks';
import { useFetch } from 'src/hooks/useFetch';
import { MessageResponse } from '../types/interfaces'; // imports the interafaces

import { generateDummyTaskTimeTwo } from 'src/utils/dummyDataUtils';

const HeatMapPage: React.FC = () => {
  // Generate dummy tasks with random dates
  // const tasksDummy = Array.from({ length: 90 }, (_, i) => ({
  //   id: i,
  //   name: `Task ${i}`,
  //   endTime: generateDummyTaskTimeTwo(1, 90).endTime, // Ensure this returns an ISO date string
  // }));
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const userId = '66f95f1805abf93e49abe2e7'; // Replace with actual user ID
  const { tasks, tasksError, tasksLoading } = useGetAllTasks(`${apiBaseUrl}/tasks/getAllTasks/${userId}`);
  const { data: useFetchResponse, error: fetchError, loading: fetchLoading } = useFetch<MessageResponse>(`${apiBaseUrl}/tasks/getAllTasks/${userId}`);

  return (
    <div>
      {/* <h1>Heatmap Demo</h1> */}
      {/* <Heatmap tasks={tasksDummy} /> */}
      {tasksLoading && <p>Loading...</p>}
      {tasksError && <p>Error: {tasksError}</p>}
      {tasks && (
        <Heatmap tasks={tasks} />
      )}
    </div>

  );
};

export default HeatMapPage;
