import React, { useState,useEffect } from "react";
import '../styles/Heatmap.css';
import{Task} from '../types/interfaces';

// interface Task {
//         id: string; 
//         name: string;
//         endTime: string;
// }
interface HeatmapProps {

    tasks: Task[];
}

const Heatmap: React.FC<HeatmapProps> = ({ tasks }) => {
    
    // State to store the number of tasks by date SCRUM-22 completed in SCRUM-23 woops
    const [tasksByDate, setTasksByDate] = useState<Map<string, number>>(new Map());

    const [offsetWeek,setOffsetWeek] = useState<number>(0); // offset to navigate weeks
    const [hoveredDay, setHoveredDay] = useState<string | null>(null); // state to store the hovered day
    
    // Arrays to represent 7 weeks and 7 days of the week
    const weeks = Array.from({ length: 7 });
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun'];

    // Process tasks to generate tasksByDate map
    // assigns tasks to specific days based on the task’s due date.
    useEffect(() => {
        const taskMap = new Map<string, number>();

        tasks.forEach((task) => {
            const date = task.timeEnd.split('T')[0]; // Get the date part
            taskMap.set(date, (taskMap.get(date) || 0) + 1);
        });
        setTasksByDate(taskMap);
    }, [tasks]);


    // Get the date for heatmap cell based on week and day index
    const getDatesForCell = (weekIndex: number, dayIndex: number) => {
        const baseDate = new Date();
        // const offsetDays = baseDate.getDay()- 20;
        const dayOfWeek = baseDate.getDay();
        const offsetToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
        baseDate.setDate(baseDate.getDate() + offsetToMonday + (offsetWeek*7)+ (weekIndex * 7) + dayIndex);
        return baseDate.toISOString().split('T')[0];
    };

    // get week labels
    const getWeekLabel = (weekIndex:number) => {
        const baseDate = new Date();
        baseDate.setDate(baseDate.getDate()+(offsetWeek*7)+(weekIndex*7))
        // return baseDate.toDateString();
        return `Week ${getWeekNumber(baseDate)}`;
    };
    const getWeekNumber = (date: Date) => {
        const yearMiliSeconds = 86400000 //(24 * 60 * 60 * 1000)
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date.valueOf() - firstDayOfYear.valueOf()) / yearMiliSeconds;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const getMonthRange=()=>{
        const baseDate = new Date();
        const endDate= new Date(baseDate);
        baseDate.setDate(baseDate.getDate() + (offsetWeek*7));
        endDate.setDate(endDate.getDate() + (offsetWeek*7) + weeks.length*6 );
        return(
            <div className="hmap-header">
                <div>{baseDate.toLocaleDateString('default', { month: 'long', day:'2-digit', year: 'numeric' })}</div>
                <div>{endDate.toLocaleDateString('default', { month: 'long', day:'2-digit', year: 'numeric' })}</div>
            </div>
        )


    }

    const resetOffsetWeek = () => {
        setOffsetWeek(0);
    }

    // Render day labels
    const renderDayLabels = () => (
        daysOfWeek.map(day => <div key={day} className="hmap-day-label">{day}</div>)
    );
    
    // debug functions testing cells
    const addTaskCountToCell = (date: string) => {
        setTasksByDate((prevTasksByDate) => {
          // Create a new Map to avoid mutating the state directly
          const newTasksByDate = new Map(prevTasksByDate);
          const currentCount = newTasksByDate.get(date) || 0;
          newTasksByDate.set(date, currentCount + 1);
          return newTasksByDate;
        });
    };
    const removeTaskCountToCell = (date: string) => {
        setTasksByDate((prevTasksByDate) => {
          // Create a new Map to avoid mutating the state directly
          const newTasksByDate = new Map(prevTasksByDate);
          const currentCount = newTasksByDate.get(date) || 0;
          newTasksByDate.set(date, currentCount - 1);
          return newTasksByDate;
        });
    };


    // Bind the task data to each cell in the calendar 
    // Render the week rows, with amount of tasks in each cell dates if necesary
    const renderWeekCells = (weekIndex:number) => (
        daysOfWeek.map((_, dayIndex) => {
            const date = getDatesForCell(weekIndex, dayIndex);
            // const taskCount = tasksByDate.get(date) || 0;
            // const taskCountClass = taskCount > 0 ? 'high' : 'empty';
            return (
                <div 
                key={dayIndex} 
                // className={`hmap-day-cells ${getColorForCell(date)}`}
                className="hmap-day-cells"
                style={{backgroundColor: getColorForCell(date)}}
                onMouseEnter={()=> setHoveredDay(date)}
                onMouseLeave={()=> setHoveredDay(null)}
                onClick={()=> addTaskCountToCell(date)}
                onAuxClick={()=> removeTaskCountToCell(date)}>
                {/* className={`hmap-cell-date ${date}`} */}
                { hoveredDay === date &&(
                <div className="hmap-day-tooltip">
                    {tasksByDate.get(date) || 0}
                    
                </div>
                )}  
                </div>
            );
        })
    );
    // lets get some colors logic to the heatmap to display colors based on amount of tasks
    const getColorForCell = (date: string) => {
        const taskCount = tasksByDate.get(date) || 0;

        const today = new Date().toISOString().split('T')[0];// define a today date
        
        if (date < today) return `rgba(248, 113, 113,${(taskCount/5)+0.5})`; // if the date is in the past, return past
        if (date === today) return `rgba(36, 251, 125,${(taskCount/5)+0.5})`; // if the date is today, return today
        if (taskCount === 0) return `#d1d5db`;        
        if (taskCount <= 3 ) return `rgba(147, 197, 253,${(taskCount/4)+0.2})`;
        if (taskCount > 3 ) return `rgba(255, 156, 0,${(taskCount/8)})`;
        
        
        // if (taskCount < 4) return 'medium';
        // return 'high';
    }

    return (
        <div className="hmap-wrapper">
        <div className="hmap-container">
            <div className="hmap-header">{getMonthRange()}</div>    
            <div className="hmap-grid">
                <div className="hmap-day-column">
                    {renderDayLabels()}
                </div>
                
                {weeks.map((_, weekIndex) => (
                    <div key={weekIndex} className="hmap-week-column">
                        <div className = "hmap-week-label"> {getWeekLabel(weekIndex)} </div>
                        
                        {renderWeekCells(weekIndex)}
                    </div>
                ))}
            </div>
            <div className="hmap-nav-container">
                <button className="hmap-nav-button" onClick={()=> setOffsetWeek(prev => prev -1)}> {"<"} </button>
                <button className="hmap-nav-button" onClick={()=> resetOffsetWeek()}> {"R"} </button>
                <button className="hmap-nav-button" onClick={()=> setOffsetWeek(prev => prev +1)}> {">"} </button>
                </div>
        </div>
        </div>
    );
};

export default Heatmap;


// <div className="hmap-cell-date">
// {/* {new Date(date).toLocaleDateString('default', { month: 'short', day: 'numeric' })} */}
// {new Date(date).toLocaleDateString('default', { month: '2-digit', day: 'numeric' })}

// </div>