/* src/routes/AppRoutes.tsx: This file manages all the routes for the application. */
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import TaskPage from "../pages/TaskPage";
import CalendarPage from "../pages/CalendarPage";
import DemoPage from "../pages/DemoPage";
import DemoLayoutPage from "../pages/DemoLayoutPage";
import LayoutTwo from "../pages/DemoTwoLayout";
import HeatMapPage from '../pages/HeatMapPage';
import TaskCreatorPage from "../pages/TaskCreatorPage";

import MainLayout from "../layouts/MainLayout";

const AppRoutes = () => (
  // <Router>
  //   <MainLayout>
  //     <Routes>
  //       <Route path="/" element={<HomePage />} />
  //       <Route path="/calendar" element={<CalendarPage />} />
  //       <Route path="/task" element={<TaskPage />} />
  //       <Route path="/demo" element={<DemoPage />} />
  //       <Route path="/layout" element={<DemoLayoutPage />} />
  //       <Route path="/layoutTwo" element={<LayoutTwo />} />
  //       <Route path="/heatmap" element={<HeatMapPage />} />
  //       <Route path="/taskCreator" element={<TaskCreatorPage />} />
        
  //     </Routes>
  //   </MainLayout>
  // </Router>
    <Router>

      <Routes>
      {/* Home page layout with TaskCreator, TasksPage, and Heatmap */}
      <Route path="/" element={ <MainLayout page="home"><HomePage /></MainLayout>}></Route>

      <Route path="/task" element={ <MainLayout page="task"><TaskPage /></MainLayout>}></Route> {/* Task page with single-column layout */}
      <Route path="/calendar" element={ <MainLayout page="calendar"><CalendarPage /></MainLayout>}></Route> {/* Calendar page with single-column layout */}
      <Route path="/taskCreator" element={ <MainLayout page="taskCreator"><TaskCreatorPage /></MainLayout>}></Route>
      <Route path="/heatmap" element={ <MainLayout page="heatmap"><HeatMapPage /></MainLayout>}></Route>
      <Route path="/demo" element={ <MainLayout page="demo"><DemoPage /></MainLayout>}></Route>
      <Route path="/layout" element={ <MainLayout page="demolayoutOne"><DemoLayoutPage /></MainLayout>}></Route>

      <Route path="/layoutTwo" element={ <MainLayout page="demoLayoutTwo"><LayoutTwo /></MainLayout>}></Route>


        
      </Routes>

  </Router>
);

export default AppRoutes;
