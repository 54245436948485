import { useState, useEffect } from 'react';
import axios from 'axios';
import { Task, MessageResponse } from '../types/interfaces'; // Adjust the import path as needed

const useGetPendingTasks = (url: string) => {
  const [pendingTasks, setPendingTasks] = useState<Task[] | null>(null);
  const [pendingTasksError, setPendingTasksError] = useState<string | null>(null);
  const [pendingTasksLoading, setPendingTasksLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPendingTasks = async () => {
      try {
        const response = await axios.get<MessageResponse>(url);
        setPendingTasks(response.data.data);
      } catch (error: any) {
        setPendingTasksError(error.message);
      } finally {
        setPendingTasksLoading(false);
      }
    };

    fetchPendingTasks();
  }, [url]);

  return { pendingTasks, pendingTasksError, pendingTasksLoading };
};

export default useGetPendingTasks;