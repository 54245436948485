import React,{Component} from "react";
// import styles from '../assets/styles/TaskTimerBar.module.css'; // import a css as a module to prevent overlaps in styling
import '../styles/TaskBarTimer.css'

interface TaskTimerBarProps{
    //something someething to interface with timer bar
    description: string;
    startTime: Date;
    endTime: Date;
    onComplete: ()=>void; // handle complete task
    onStar: ()=>void; // handle star task
    onRemove: ()=>void; // handle remove task
}

interface TaskTimerBarState{
    //something something to interface bar state
    currentTime: Date;
    taskState: 'Incoming'|'In Progress'|'Complete';
}

class TaskTimerBar extends Component<TaskTimerBarProps,TaskTimerBarState>{
    private intervalID: NodeJS.Timeout|null=null;

    // constructor
    constructor(props: TaskTimerBarProps){
        super(props);
        this.state={
            currentTime: new Date(),
            taskState: 'Incoming', // default state bc task usually are not active
        }
    }
    componentDidMount(): void {
        this.intervalID=setInterval(()=>{
            this.updateTaskState();
        },1000) //update every second
    }
    componentWillUnmount(): void {
        if(this.intervalID){
            clearInterval(this.intervalID);
        }
        
    }
    // properly update taskstate and current time
    updateTaskState(): void {
        const { startTime, endTime } = this.props;
        const currentTime = new Date();

        let taskState: 'Incoming' | 'In Progress' | 'Complete' = 'Incoming';
        if (currentTime >= startTime && currentTime <= endTime) {
            taskState = 'In Progress';

        } else if (currentTime > endTime) {
            taskState = 'Complete';
        }
        this.setState({ currentTime, taskState });
    }
    



    // lets calculate a time percentage left in the rpgressbar
    getTimePercentage(): number {
        const { startTime, endTime } = this.props;
        const { currentTime } = this.state;

        if (currentTime < startTime) return 0; //not started
        if (currentTime > endTime) return 100; //task has finished

        const totalTime = endTime.getTime() - startTime.getTime();
        const elapsedTime = currentTime.getTime() - startTime.getTime();

        return (elapsedTime / totalTime) * 100;
    }

    //

    //Render
    render(){
        const { description,
            startTime,
            endTime,
            onComplete,
            onRemove,
            onStar } = this.props;
        const {taskState, currentTime}=this.state;
        // Format times for display

        const formatDate = (date: Date) => {

        return date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
            });
            };
        return(
            // some html div structure
            // Hover State
            //Timer Container
            <div className="container">
                <div className="task">
                    <div 
                        className="fill"
                        role="progressbar"// use for testing
                        style={{
                        width: `${this.getTimePercentage()}%`
                        }}></div>
                        
                    <div className="taskWrapper">
                        <button className="taskCompleteButton" onClick={onComplete}>✔</button>
                        
                        <div className="taskDescription">
                            <div className="taskTitle">{description}</div>
                            <div className="taskDate">{`Due: ${formatDate(endTime)}`}</div>
                        </div>
                        
                        <button className="starButton" onClick={onStar}>★</button>
                        <button className="removeButton" onClick={onRemove}>x</button>
                        </div>
                    <div className="indicatorBar">
                        <div>{`Start: ${formatDate(startTime)}`}</div>
                        <div>{taskState} - {this.getTimePercentage().toFixed(0)}%</div>
                        <div>{`Due: ${formatDate(endTime)}`}</div>
                    </div>
                    </div>
                
            </div>
       
            //Task Description
            //Task Due Date Day:Time:PM
            //Timer Progress Bar Container
            //Timer Progress Bar
            //Timer State Incoming,In Progress, Complete + %
            // CP-18 I made completed it while working on CP-20 woops! 

        );
    }


}
export default TaskTimerBar;