import { useState } from 'react';
import axios from 'axios';

const useDeleteTask = (taskId: string, onTaskDeleted: () => void) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const deleteTask = async () => {
    setLoading(true);
    setError(null);
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/tasks/deleteTask/${taskId}`);
      onTaskDeleted();
      window.location.reload(); // Trigger a page reload
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { deleteTask, loading, error };
};

export default useDeleteTask;