import { useFetch } from '../hooks/useFetch'; // Adjust the import path as needed
import useGetPendingTasks from '../hooks/useGetPendingTasks';
import useGetCompletedTasks from '../hooks/useGetCompletedTasks';
import TimerBar from '../components/TaskTimerBar';
import { MessageResponse } from '../types/interfaces'; // imports the interafaces
import TaskCreator from 'src/components/TaskCreator';

/*************************************************
 * How to call hooks. Hooks are what is used to make API to the backend
 * 1. import the hood: import { useFetch } from '../hooks/useFetch';
 * 2. Call the hook with the expected response type
 *    look for the commented part and model your code after it
 * 3. Use the response data in your component
 *************************************************/

/*************************************************
 * The interface has been abstracted to types/interfaces.ts
 * Leaving this here for now so people can see.
 *************************************************/
// interface Task {
  // _id: string;
  // taskTitle: string;
  // taskStatus: boolean;
  // creationDate: string;
  // timeStart: string;
  // timeEnd: string;
  // dueDate: string;
  // taskUserID: string;
// }

// interface MessageResponse {
//   message: string;
//   data: Task[]; // Define expected response structure
// }
//************************************************** */
const HomePage = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const userId = '66f95f1805abf93e49abe2e7'; // Replace with actual user ID


  // Call the hook with the expected response type
  const { data: useFetchResponse, error: fetchError, loading: fetchLoading } 
                = useFetch<MessageResponse>
                      (`${apiBaseUrl}/home?userId=${userId}`);

  // Call the hook for useGetPendingTasks
  const { pendingTasks, pendingTasksError, pendingTasksLoading } = useGetPendingTasks(`${apiBaseUrl}/tasks/getPendingTasks/${userId}`);

  // Call the hook for useGetCompletedTasks
  const { completedTasks, completedTasksError, completedTasksLoading } = useGetCompletedTasks(`${apiBaseUrl}/tasks/getCompletedTasks/${userId}`);

  // Render part of the component
  return (
    <>
    <div>
      <TaskCreator />
      {/* <h1>Welcome to the Home Page</h1>
      <p>This is the home page of our application.</p> */}
      {fetchLoading && <p>Loading...</p>}
      {fetchError && <p>Error: {fetchError}</p>}
      {useFetchResponse && (
        <div>
          {useFetchResponse.data.map(task => (
            <div key={task._id}>
              <TimerBar 
                  taskId={task._id}
                  taskTitle={task.taskTitle}
                  startTime={new Date(task.timeStart)}
                  taskStatus={task.taskStatus}
                  endTime={new Date(task.timeEnd)}
                  dueDate={new Date(task.dueDate)}
                  onComplete={() => {}}
                  onRemove={() => {}}
                  onFetchTasks={() => {}}
              />
            </div>
          ))}
        </div>
      )}
    </div>
    <div className='server-info'>
    <h2>Pending Tasks</h2>
    {pendingTasksLoading && <p>Loading...</p>}
    {pendingTasksError && <p>Error: {pendingTasksError}</p>}
    {pendingTasks && (
      <div>
        {pendingTasks.map(task => (
          <div key={task._id}>
            <h2>{task.taskTitle}</h2>
              <p>Status: {task.taskStatus ? 'Completed' : 'Pending'}</p>
              <p>Start Time: {new Date(task.timeStart).toLocaleString()}</p>
              <p>End Time: {new Date(task.timeEnd).toLocaleString()}</p>
              <p>User ID: {task.taskUserID}</p>
          </div>
        ))}
      </div>
    )}
  </div>
  <div>
    <h2>Completed Tasks</h2>
    {completedTasksLoading && <p>Loading...</p>}
    {completedTasksError && <p>Error: {completedTasksError}</p>}
    {completedTasks && (
      <div>
        {completedTasks.map(task => (
          <div key={task._id}>
            <h2>{task.taskTitle}</h2>
            <p>Status: {task.taskStatus ? 'Completed' : 'Pending'}</p>
            <p>Start Time: {new Date(task.timeStart).toLocaleString()}</p>
            <p>End Time: {new Date(task.timeEnd).toLocaleString()}</p>
            <p>User ID: {task.taskUserID}</p>
          </div>
        ))}
      </div>
    )}
  </div>
  </>
  );
};

export default HomePage;