// frontend/src/components/TaskTimerBar.tsx
/************************************************************
 * 
 * This component is used to display a task timer bar.
 * All of the general purpose functions of the component are handled
 * by the useTaskTimerBar and use<databaseFunction> hooks.
 * useTaskTimerBar functions:
 * - currentTime, taskState, getTimePercentage
 * database functions:
 * - useUpdateStatus: updates taskStatus and triggers a page reload
 * 
 * Note: the Props interface is defined in the TaskTimerBarProps file
 * 
 ************************************************************/
import React from 'react';
import useTaskTimerBar from '../utils/useTaskTimerBar';
import useUpdateStatus from '../hooks/useUpdateStatus';
import useDeleteTask from '../hooks/useDeleteTask';
import { TaskTimerBarProps } from '../types/interfaces'; //TTB
import styles from '../styles/TaskTimerBar.module.css'; // We do not use .modules.css
import '../styles/TaskBarTimer.css'; // We use .css


const TaskTimerBar: React.FC<TaskTimerBarProps> = ({ 
                  taskId, taskTitle, taskStatus, startTime, endTime, 
                  onComplete, onRemove, onFetchTasks }) => {
  const { currentTime, taskState, getTimePercentage } = useTaskTimerBar(startTime, endTime);
  const { handleComplete, loading: completeLoading, loading: completeError } = useUpdateStatus(taskId, onFetchTasks);
  const { deleteTask, loading: deleteLoading, error: deleteError } = useDeleteTask(taskId, onRemove);

  const formatDate = (date: Date, timeOnly =false) => {
    return date.toLocaleString('en-US',timeOnly ? {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      } : {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  return (
    <div className="container">
      <div className="task">
        <div
          className="fill"
          role="progressbar"
          style={{ width: `${getTimePercentage()}%` }}
        ></div>
        <div className="taskWrapper">
        {!taskStatus ? (
            <button className={styles.buttonTaskComplete} onClick={handleComplete} disabled={completeLoading}>✔</button>
          ) : (
            <button className={`${styles.buttonTaskComplete} ${styles.completedTask}`} onClick={handleComplete}>✔</button>
          )}
          <div className="taskDescription">
            <div className="taskTitle">{taskTitle}</div>
            <div className="taskDate">{`Due: ${formatDate(endTime)}`}</div>
          </div>
          <button className="removeButton" onClick={deleteTask} disabled={deleteLoading}>x</button>
        </div>
        <div className="indicatorBar">
          <div>{`Start: ${formatDate(startTime,true)}`}</div> {/* true is for timeOnly */}
          <div>{taskState} - {getTimePercentage().toFixed(0)}%</div>
          <div>{`Due: ${formatDate(endTime,true)}`}</div>
        </div>
      </div>
    </div>
  );
};

export default TaskTimerBar;