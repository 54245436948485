import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "../styles/StaticCalendar.css";
import { Task } from "../types/interfaces"; // Import the full model
import useGetPendingTasks from "../hooks/useGetPendingTasks"; // Gets all the Pending Tasks
import isDateWithinTaskRange from "../utils/checkDayForTasks"; // Current Date === Task Date
import TaskModal from "./TaskPopup"; // Modal for Task Details
import { VscBook, VscOutput } from "react-icons/vsc";
import { Value } from "react-calendar/dist/cjs/shared/types";

/*Putting This Here So I can Merge*/
// interface Task {
//   timeStart: string;
//   timeEnd: string;
//   dueDate: string;
// }

function StaticCalendar() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const userId = '66f95f1805abf93e49abe2e7'; // Replace with actual user ID
  const [currentDate, setCurrentDate] = useState(new Date());
  const [value, onChange] = useState<Value>(new Date());

  // Calls the useGetPendingTasks hook to get all the pending tasks
  const { pendingTasks, pendingTasksError, pendingTasksLoading } = useGetPendingTasks(`${apiBaseUrl}/tasks/getPendingTasks/${userId}`);
  
  // modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tasksForSelectedDate, setTasksForSelectedDate] = useState<Task[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('');
  
  useEffect(() => {
      // This hook ensures that the current date is always set when the component loads
      setCurrentDate(new Date());
    }, []);
  
    const getMonthName = (date: Date) => {
      return date.toLocaleString('default', { month: 'long' }); // Get the full name of the month
    };
  
    const getDay = (date: Date) => {
      return date.getDate(); // Get the current day (1-31)
    };
  
    const getYear = (date: Date) => {
      return date.getFullYear(); // Get the full year (e.g., 2024)
    };
    //**********************************************
    // Checks today's date and time against the startTime and endTime
    // attributes of each task. If the current time is between the 
    // startTime and endTime, it returns true
    //**********************************************/
    const checkForPendingTasks = (date: Date) => {
      if (!pendingTasks) return false;
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date to midnight
      if (date < today) return false; // Only check for today and future dates

      return pendingTasks.some((task: Task) => {
        const startTime = new Date(task.timeStart);
        const endTime = new Date(task.timeEnd);
        return isDateWithinTaskRange(date, { timeStart: startTime, timeEnd: endTime });
      });
    };

    //**********************************************
    // Checks today's date and time against the dueDate attribute of each task.
    // If == dueDate, it returns true and displays a task symbol on the calendar 
    // NOTE: dueDate is currently set to the timeEnd attribute because the
    //       addTask function is not adding a dueDate correctly.
    //       In the future: change task.timeEnd to task.dueDate
    // ALSO: I left the old checkForPendingTasks function commented out above
    //       in case they decide that is the way they want the icons added
    //**********************************************/
    // const checkForPendingTasks = (date: Date) => {
    //   if (!pendingTasks) return false;
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0); // Normalize today's date to midnight
    //   if (date < today) return false; // Only check for today and future dates
    //   return pendingTasks.some((task: Task) => {
    //     const dueDate = new Date(task.timeEnd);
    //     dueDate.setHours(0, 0, 0, 0); // Normalize task end date to midnight
    //     return date.getTime() === dueDate.getTime();
    //   });
    // };
    
    //***********************************************
    // Def: handleDateClick
    // Params: date: Date
    // Returns: void
    // Checks and filters pending tasks. If the date is within the task's
    // start and end time, it sets the tasks for the selected date and opens
    // the modal to display the tasks.
    //**********************************************/
    const handleDateClick = (date: Date) => {
      if (!pendingTasks) return;
      const tasksForDate = pendingTasks.filter((task: Task) => {
        const startTime = new Date(task.timeStart);
        const dueDate = new Date(task.timeEnd); // Change to task.dueDate when available
        startTime.setHours(0, 0, 0, 0); // Normalize task start date to midnight
        dueDate.setHours(0, 0, 0, 0); // Normalize task due date to midnight
        return date >= startTime && date <= dueDate;
      });
      setTasksForSelectedDate(tasksForDate);
      setSelectedDate(date.toDateString());
      setIsModalOpen(true);
    }; // code is using filtering with pendin tasks, next task is to filter using the times the tasks were submitted
    
    if (pendingTasksLoading) {
      return <div>Loading...</div>;
    }
    
    if (pendingTasksError) {
      return <div>Error: {pendingTasksError}</div>;
    }
  
    return (
      <div className="calendar-container">
      <div className="custom-header">
          <span className="custom-month">{getMonthName(currentDate)}</span>
          <span className="custom-day">{getDay(currentDate)}</span>
          <span className="custom-year">{getYear(currentDate)}</span>
        </div>
        <Calendar
          onChange={onChange}
          value={value}
          onClickDay={(date) => handleDateClick(date)} // moved click to here to make entire thing clickable
          tileContent={({ date, view }) =>
            view === 'month' && checkForPendingTasks(date) ? (
              <span className="taskSymbol"><VscOutput /></span>
            ) : null
          }
          next2Label={null} // Disable double next buttons (optional)
          prev2Label={null} // Disable double prev buttons (optional)
        />
        {isModalOpen && (
        <TaskModal
          tasks={tasksForSelectedDate}
          onClose={() => setIsModalOpen(false)}
          selectedDate={selectedDate}
        />
      )}
      </div>
    );
  }

export default StaticCalendar;