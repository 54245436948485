/** This file is probably unnecesary, but instead of having ot find the
 * current hardcoded valuues for certain fields, we can just import them from here :D
 *  */

/** Hardcoded values for the server
 * @param taskUserID - The user ID of the task
 * @param ApiTaskAddres - The address of the API to add a task
 * @returns The current hardcoded values for the server
 */
export const hardcodedServerValues = {
    taskUserID: '66f95f1805abf93e49abe2e7', 
    ApiTaskAddres: 'http://localhost:5000/api/tasks/add'
}
