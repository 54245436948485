import React from "react"; 
import StaticCalendar from "../components/StaticCalendar";

const CalendarPage = () => {
  return (
    <div>
      {/* <h1>Welcome to the Calendar Page</h1> */}
      {/* <p>This is the calendar page of our application.</p> */}
      <StaticCalendar></StaticCalendar>
    </div>
  );
};
export default CalendarPage;