/* src/App.tsx: this file is focused on rendering the routes.
*/ 

import React from 'react';
import AppRoutes from './routes/AppRoutes';
import './App.css';

function App(){
    return <AppRoutes />;
}

export default App;