import React, { useState, useEffect } from 'react';
import TimerBar from '../components/TaskTimerBar';
import useGetAllTasks from '../hooks/useGetAllTasks';
import useGetPendingTasks from '../hooks/useGetPendingTasks';
import useGetCompletedTasks from '../hooks/useGetCompletedTasks';
// import styles from '../styles/TaskPage.module.css'; // Do not use module.css
import '../styles/TaskPage.css';


const TaskPage: React.FC = () => {
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const userId = '66f95f1805abf93e49abe2e7'; // Replace with actual user ID

  // Call the hook with the expected response type
  const { tasks, tasksError, tasksLoading } = useGetAllTasks(`${apiBaseUrl}/tasks/getAllTasks/${userId}`);
  const { pendingTasks, pendingTasksError, pendingTasksLoading } = useGetPendingTasks(`${apiBaseUrl}/tasks/getPendingTasks/${userId}`);
  const { completedTasks, completedTasksError, completedTasksLoading } = useGetCompletedTasks(`${apiBaseUrl}/tasks/getCompletedTasks/${userId}`);

   // Helper function to format date
   const formatDate = (date: string | undefined) => 
    date && !isNaN(new Date(date).getTime()) ? new Date(date).toLocaleString() : 'Not Set';

   const renderTasks = (tasks: any[] | null, loading: boolean, error: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined, status: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined) => (
    <>
      <h2>{status} Tasks</h2>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {tasks && (
        <div>
          {tasks.map(task => (
            <div key={task._id} className="taskCard">
              {showDebugInfo && (
                <p className='admonition'>
              Server Debug Information:<br />
              <h3>{task.taskTitle}</h3>
              Task ID: {task._id}
              Status: {task.taskStatus ? 'Completed' : 'Pending'}<br />
              Creation Date: {formatDate(task.creationDate)}<br />
              Start Time: {formatDate(task.timeStart)}<br />
              End Time: {formatDate(task.timeEnd)}<br />
              Due Date: {formatDate(task.dueDate)}<br />
              User ID: {task.taskUserID}
              </p>
              )}

              <TimerBar
                taskId={task._id}
                taskTitle={task.taskTitle}
                taskStatus={task.taskStatus}
                startTime={new Date(task.timeStart)}
                endTime={new Date(task.timeEnd)}
                dueDate={new Date(task.dueDate)}
                onComplete={() => {}}
                onRemove={() => {}}
                onFetchTasks={() => {}}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
  return (
    <div>
      <section className="debug-information">
        <button className = 'debug-button'onClick={() => setShowDebugInfo(!showDebugInfo)}>Toggle Debug Information 
          {showDebugInfo ? ' Off' : ' On'}
        </button>

        {/* <h1>Task Page</h1> */}
        {/* <p>Uses the useGetAllTasks hook.</p> */}
        <div className="taskColumns">
          <div className="taskColumn">
            {renderTasks(pendingTasks, pendingTasksLoading, pendingTasksError, 'Pending')}
          </div>
          <div className="taskColumn">
            {renderTasks(completedTasks, completedTasksLoading, completedTasksError, 'Completed')}
          </div>
        </div>
      </section>
    </div>
  );
}; 
  
  
   // return (
  //   <div>
  //     <h1>Task Page</h1>
  //     <p>uses the useGetAllTasks hook.</p>
  //     <div className={styles.taskColumns}>
  //       <div className={styles.taskColumn}>
  //         <h2>Pending Tasks</h2>
  //         {pendingTasksLoading && <p>Loading...</p>}
  //         {pendingTasksError && <p>Error: {pendingTasksError}</p>}
  //         {pendingTasks && (
  //           <div>
  //             {pendingTasks.map(task => (
  //               <div key={task._id} className={styles.taskCard}>
  //                 <h3>{task.taskTitle}</h3>
  //                 <p>Task ID: {task._id}</p>
  //                 <p>Status: {task.taskStatus ? 'Completed' : 'Pending'}</p>
  //                 <p>Creation Date: {new Date(task.creationDate).toLocaleString()}</p>
  //                 <p>
  //                   Start Time:  
  //                   {task.timeStart && !isNaN(new Date(task.timeStart).getTime())
  //                     ? new Date(task.timeStart).toLocaleString()
  //                     : ' Not Set'}
  //                 </p>
  //                 <p>End Time: {new Date(task.timeEnd).toLocaleString()}</p>
  //                 <p>Due Date: {new Date(task.dueDate).toLocaleString()}</p>
  //                 <p>User ID: {task.taskUserID}</p>

  //                 <TimerBar 
  //                   taskId={task._id}
  //                   taskTitle={task.taskTitle}
  //                   startTime={new Date(task.timeStart)}
  //                   taskStatus={task.taskStatus}
  //                   endTime={new Date(task.timeEnd)}
  //                   dueDate={new Date(task.dueDate)}
  //                   onComplete={() => {}}
  //                   onRemove={() => {}}
  //                   onFetchTasks={() => {}}
  //                 />
  //               </div>
  //             ))}
  //           </div>
  //         )}
  //       </div>
  //       <div className={styles.taskColumn}>
  //         <h2>Completed Tasks</h2>
  //         {completedTasksLoading && <p>Loading...</p>}
  //         {completedTasksError && <p>Error: {completedTasksError}</p>}
  //         {completedTasks && (
  //           <div>
  //             {completedTasks.map(task => (
  //               <div key={task._id} className={styles.taskCard}>
  //                 <h3>{task.taskTitle}</h3>
  //                 <p>Task ID: {task._id}</p>
  //                 <p>Status: {task.taskStatus ? 'Completed' : 'Pending'}</p>
  //                 <p>Creation Date: {new Date(task.creationDate).toLocaleString()}</p>
  //                 <p>
  //                   Start Time:  
  //                   {task.timeStart && !isNaN(new Date(task.timeStart).getTime())
  //                     ? new Date(task.timeStart).toLocaleString()
  //                     : ' Not Set'}
  //                 </p>
  //                 <p>End Time: {new Date(task.timeEnd).toLocaleString()}</p>
  //                 <p>Due Date: {new Date(task.dueDate).toLocaleString()}</p>
  //                 <p>User ID: {task.taskUserID}</p>
  //                 <TimerBar 
  //                   taskId={task._id}
  //                   taskTitle={task.taskTitle}
  //                   taskStatus={task.taskStatus}
  //                   startTime={new Date(task.timeStart)}
  //                   endTime={new Date(task.timeEnd)}
  //                   dueDate={new Date(task.dueDate)}
  //                   onComplete={() => {}}
  //                   onRemove={() => {}}
  //                   onFetchTasks={() => {}}
  //                 />
  //               </div>
  //             ))}
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // );

export default TaskPage;