// frontend/src/hooks/useUpdateStatus.ts
/************************************************************
 * 
 * This hook is used to update the status of a task and handles the
 * refresh of the browser window. Currently it's forcing a page reload
 * 
 ************************************************************/
import { useState } from 'react';
import axios from 'axios';

const useUpdateStatus = (taskId: string, onFetchTasks: () => void) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  /**************************************************
   * Def: handleComplete
   * Togges the taskStatus and updates the database.
   *************************************************/
  const handleComplete = async () => {
    setLoading(true);
    setError(null);
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/updateTaskState/${taskId}`);
      onFetchTasks();
      window.location.reload(); // Trigger a page reload
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return { handleComplete, loading, error };
};

export default useUpdateStatus;