import React,{Component} from 'react';
import '../styles/ClockBar.css';

interface ClockBarState{
    time: Date;
    hover: boolean;
}

class ClockBar extends Component<{}, ClockBarState>{
    private intervalID: NodeJS.Timeout|null=null;

    constructor(props:{}){
        super(props);
        this.state={
            time: new Date(),
            hover: false,
        };
    }
    // Retrigger rendering
    componentDidMount(): void {
        this.intervalID=setInterval(()=>{
            this.setState({time: new Date()});
        }, 1000); //update everysecond
        
    }
    //Stop invterval when unmounted
    componentWillUnmount(): void {
        if(this.intervalID){
            clearInterval(this.intervalID);
        }
        
    }
    // format time
    formatTime(date: Date): string{
        let hours =date.getHours();
        // const hours=(date.getHours()).toString().padStart(2,'0');
        const minutes=date.getMinutes().toString().padStart(2,'0');
        const seconds=date.getSeconds().toString().padStart(2,'0');

        //ampm check
        const ampm=hours>=12?'PM':'AM';

        //convert 12 hour format
        hours = hours %12;
        hours = hours ? hours:12 //
        const hourString = hours.toString().padStart(2,'0');

        //
        return `${hourString}:${minutes}:${seconds} ${ampm}`;

    }
    // lets calculate a time percentage
    getTimePercentage():number{
        const totalMinutes=24*60;
        const currentMinutes=this.state.time.getHours()*60+this.state.time.getMinutes();
        return (currentMinutes/totalMinutes)*100;
    }

    getRemainingTime():string{
        const remainingMinutes =(24*60)-(this.state.time.getHours()*60+this.state.time.getMinutes());
        const hoursLeft=Math.floor(remainingMinutes/60).toString().padStart(2,'0');
        const minutesLeft=Math.floor(remainingMinutes%60).toString().padStart(2,'0');
        return `${hoursLeft}:${minutesLeft}`;
        // if(hoursLeft<1){
        //     return `${remainingMinutes} minutes`; // Less than 1 hour remaining
        // }else{
        //     return `${hoursLeft} hours and ${minutesLeft} minutes`; // 1 hour or more remaining

        // }

    }


    //ticks
    renderTicks=()=>{
        const ticks=[];
        for(let i=1; i <=23;i++){
            ticks.push(
                <div
                key={i}
                className='tick'
                style={{left:`${(i/24)*100}%`}}// tickbar positions
                ></div>
            );
            
        }
        return ticks;
    }
    render() {
        // debug
        const{hover}=this.state;
        if(hover){console.log("tooltipe visible")};
        return (
          <div
            className="clock-bar"
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <div
              className="fill"
              role="progressbar"// use for testing
              style={{
                width: `${this.getTimePercentage()}%`
              }}
              ></div>
              {this.renderTicks()}
            
            <div className="time-display">
              {this.formatTime(this.state.time)} {/* Displays the current time */}
            </div>
              <div className='indicator-bar'>
              <div>00:00 AM</div>
              <div>Remaining Time: {this.getRemainingTime()}</div>
              <div>12:00 PM</div>


              </div>
            
            {hover && (
              <div className="tooltip">
                Remaining Time: {this.getRemainingTime()}
              </div>
            )}
            
          </div>
        );
      }
}

// what do we need?
// init timer clock
// stop clock 
// format time
// get time
// get remaining time

// event handlers


export default ClockBar;