// frontend/src/hooks/useTaskTimer.ts
import { useState, useEffect } from 'react';

interface TaskTimerState {
  currentTime: Date;
  taskState: 'Incoming' | 'In Progress' | 'Complete';
}

const useTaskTimerBar = (startTime: Date, endTime: Date) => {
   const [currentTime, setCurrentTime] = useState(new Date());
   const [taskState, setTaskState] = useState<TaskTimerState['taskState']>('Incoming');

   useEffect(() => {
      const intervalID = setInterval(() => {
         setCurrentTime(new Date());
         updateTaskState();
         //updateTaskStateBasedOnTime();
      }, 1000); // update every second

      return () => {
         clearInterval(intervalID);
      };
   }, [startTime, endTime]);

   /************************************
    * Update the task state based on the current time
    * Incoming: currentTime < startTime
    * In Progress: currentTime >= startTime && currentTime <= endTime
    * Complete: currentTime > endTime
    ************************************/
   const updateTaskState = () => {
      const currentTime = new Date();
  
      let taskState: 'Incoming' | 'In Progress' | 'Complete' = 'Incoming'; // default is "Incoming"
      if (isNaN(startTime.getTime())) {
        taskState = 'Incoming';
      } else if (currentTime >= startTime && currentTime <= endTime) {
        taskState = 'In Progress';
      } else if (currentTime > endTime) {
        taskState = 'Complete';
      }
      setTaskState(taskState);
    };


   /************************************
    * Calculate the percentage of time elapsed
   ************************************/
   const getTimePercentage = () => {
      const totalDuration = endTime.getTime() - startTime.getTime();
      const elapsedTime = currentTime.getTime() - startTime.getTime();
      const percentage = (elapsedTime / totalDuration) * 100;
      return Math.min(Math.max(percentage, 0), 100); // Ensure the percentage is between 0 and 100
   };

   return { currentTime, taskState, getTimePercentage };
};

export default useTaskTimerBar;