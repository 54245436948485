import React from "react";
import "../styles/TaskPopup.css";
import { Task } from "../types/interfaces"; // import the full model


//*******************************************
// Full Task Model for Reference
//
// export interface Task {
//   _id: string;
//   taskTitle: string;
//   taskStatus: boolean;
//   creationDate: string;
//   timeStart: string;
//   timeEnd: string;
//   dueDate: string;
//   taskUserID: string;
// } 
//******************************************/

//******************************************
// NOTE:
// API calls - No calls are made here. They're made in the Calendar
//             component and passed to here.
// Due Date - there is an issue with its implementation of adding a task.
//           In this file, I have it showing the timeEnd attribute
//*****************************************/

interface TaskModalProps {
  tasks: Task[];
  onClose: () => void;
  selectedDate: string;
}

const TaskModal: React.FC<TaskModalProps> = ({
  tasks,
  onClose,
  selectedDate,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Tasks for {selectedDate}</h2>
        <ul>
          {tasks.map((task) => {
            const endDate = new Date(task.timeEnd);
            const formattedDate = endDate.toLocaleDateString("default", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            });
            const formattedTime = endDate.toLocaleTimeString("default", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            
            return (
              <li key={task._id}>
                <p>Title: {task.taskTitle}</p>
                <p>Status: {task.taskStatus ? "Completed" : "Pending"}</p>
                <p>Due Date: {`${formattedDate}, ${formattedTime}`}</p>
              </li>
            );
          })}
        </ul>
            <button className="close-button" onClick={onClose}>
              Close
            </button>
      </div>
    </div>
  );
};

export default TaskModal;
