import { useState, useEffect } from 'react';
import axios from 'axios';
import { Task, MessageResponse } from '../types/interfaces'; // Adjust the import path as needed

const useGetCompletedTasks = (url: string) => {
  const [completedTasks, setCompletedTasks] = useState<Task[] | null>(null);
  const [completedTasksError, setCompletedTasksError] = useState<string | null>(null);
  const [completedTasksLoading, setCompletedTasksLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPendingTasks = async () => {
      try {
        const response = await axios.get<MessageResponse>(url);
        setCompletedTasks(response.data.data);
      } catch (error: any) {
        setCompletedTasksError(error.message);
      } finally {
        setCompletedTasksLoading(false);
      }
    };

    fetchPendingTasks();
  }, [url]);

  return { completedTasks, completedTasksError, completedTasksLoading };
};

export default useGetCompletedTasks;