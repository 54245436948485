import useGetPendingTasks from "../hooks/useGetPendingTasks";

interface Task {
   timeStart: Date;
   timeEnd: Date;
}

const isDateWithinTaskRange = (
   date: Date, 
   task: { 
      timeStart: Date, 
      timeEnd: Date 
   }): boolean => {
   return date >= task.timeStart && date <= task.timeEnd;
};

export default isDateWithinTaskRange;