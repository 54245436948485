import React from "react";
import ClockBar from "../components/ClockBar";
import TaskTimerBar from "../components/DEMO_TaskTimerBar";

const DemoPage = () => {
  
  //to debug demo clockbar
  const startTime = new Date(); // Start time: current time
  const endTime = new Date(startTime.getTime() +1 * 60 * 1000); // End time: 1 hour from startTime
  
    return (
    <div>
      <h1>Welcome to the Components Demo Page</h1>
      <p>This is the demo page of our application.</p>
        <ClockBar />
        <h4>Task Timer Bars</h4>
        <p>Demo Timer with Conditions</p>
        <TaskTimerBar
        description='This Task is incoming'
        startTime={new Date(startTime.getTime() + 5 * 60 * 1000)}
        endTime={new Date(startTime.getTime() + 6 * 60 * 1000)} onComplete={function (): void {
          throw new Error('Function not implemented.');
        } } onStar={function (): void {
          throw new Error('Function not implemented.');
        } } onRemove={function (): void {
          throw new Error('Function not implemented.');
        } }      />
      <TaskTimerBar
        description='This is a Task in Progress'
        startTime={startTime}
        endTime={endTime} onComplete={function (): void {
          throw new Error('Function not implemented.');
        } } onStar={function (): void {
          throw new Error('Function not implemented.');
        } } onRemove={function (): void {
          throw new Error('Function not implemented.');
        } }      />
      <TaskTimerBar
        description='This is Taskthat is Complete'
        startTime={new Date(startTime.getTime() - 30 * 60 * 1000)}
        endTime={new Date(startTime.getTime() - 30 * 60 * 1000)} onComplete={function (): void {
          throw new Error('Function not implemented.');
        } } onStar={function (): void {
          throw new Error('Function not implemented.');
        } } onRemove={function (): void {
          throw new Error('Function not implemented.');
        } }      />
    </div>
  );
}

export default DemoPage;