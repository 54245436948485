import { useState, useEffect } from 'react';
import axios from 'axios';
import { Task, MessageResponse } from '../types/interfaces'; // Adjust the import path as needed

const useGetAllTasks = (url: string) => {
   const [tasks, setTasks] = useState<Task[] | null>(null);
   const [tasksError, setTasksError] = useState<string | null>(null);
   const [tasksLoading, setTasksLoading] = useState<boolean>(true);
 
   useEffect(() => {
     const fetchAllTasks = async () => {
       try {
         const response = await axios.get<MessageResponse>(url);
         setTasks(response.data.data);
       } catch (error: any) {
         setTasksError(error.message);
       } finally {
         setTasksLoading(false);
       }
     };
 
     fetchAllTasks();
   }, [url]);
 
   return { tasks, tasksError, tasksLoading };
 };
 
 export default useGetAllTasks;